import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import LbsNavbar from "@components/navbar"
import Footer from "@components/footer"
import Announcement from "@components/announcement"

const CaseStudy = () => {
  return (
    <Layout>
      <SEO title="Gotenerife Case Study" />
      <div className="page-layout">
        <section className="intro intro--no-waves">
          <Announcement />
          <div className="container">
            <LbsNavbar />
            <div className="container-blog container text-left">
              <h1 className="labs-title">
                Case Study - GoTenerife
                <span className="labs-blue">.</span>
              </h1>
            </div>
          </div>
        </section>
        <div className="intro-waves"></div>
        <section className="wave-wrapper">
          <section className="dark-bg page-wrapper">
            <div className="container-blog-content container">
              <div className="dark-bg page-container">
                <div>
                  <div className="row team-page">
                    <div className="members-list col-xl-3 col-lg-4 col-md-4">
                      <h3>Tech Stack:</h3>
                      <span className="badge badge-light">React Native</span>
                      <br />
                      <span className="badge badge-light"> Node.js</span> <br />
                      <span className="badge badge-light"> Firebase</span>
                      <br />
                      <span className="badge badge-light"> React</span> <br />
                    </div>
                    <div className="member-details col-xl-9 col-lg-8 col-md-8 ">
                      <section className="pt-6 pt-md-0 case-study-page">
                        <p className="pt-2">
                          <strong>Case:</strong> How we helped GoTenerife to
                          build a local application for tourists to get the best
                          deals on Tenerife.
                        </p>
                        <p className="pt-2">
                          <strong>Industry:</strong> TRAVEL
                        </p>
                        <p className="pt-2">
                          <strong>Platform:</strong> Ios/Android, Web
                        </p>

                        <p className="pt-4">
                          <strong>Intro</strong>
                          <br />
                          After a successful launch of several digital products
                          in Europe, founders of GoTenerife relocated to
                          Tenerife. Facing difficulties to explore the entire
                          island as a local, they started a digital product
                          aimed to bring the best offers and tickets to
                          destinations with instant booking for travelers in
                          Tenerife.
                        </p>
                        <p className="pt-4">
                          <strong>Process</strong>
                          <p>
                            Knowing that most products pivot on their way to the
                            market, we mapped out the customer experience,
                            planned two short releases, then hit the ground
                            running.
                          </p>
                          <p>
                            The initial release needed to do two things: be a
                            production-ready application that could be used to
                            acquire local customers, list their tours and
                            tickets, and be a compelling experience to attract
                            tourists. Luckily, the product lead and design team
                            are ruthless prioritizer.
                          </p>
                          <p>
                            We rapidly pieced together some initial branding,
                            visual language and began building out the feature
                            set. In addition to building the first version of
                            core platform features, we also implemented a split
                            testing framework using a low-level integration with
                            A/B user testing software.
                          </p>
                        </p>
                        <p className="pt-4">
                          <strong>Results</strong>
                          <br />
                          After the initial 6-week release, a second 4-week
                          pivot and polish phase firmed up the product and
                          helped identify the next business components to
                          address. After months of hard work, including tons of
                          new features and a new brand and visual design by the
                          design team, the platform officially launched with a
                          300 locals and hundreds of offers.
                        </p>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="dark-bg"></section>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}

export default CaseStudy
